(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

/**
 * The following scripts get inlined into a <script> tag in
 * the <head> of the document via em_render_blocking_scripts()
 * which is tied into the wp_head hook.
 *
 * @see ../functions/enqueue.php
 *
 * @since 1.0.1
 */

/**
 * Remove the no-js class from <html>
 *
 * @since 1.0.1
 */
document.documentElement.classList.remove('no-js');

/**
 * Add the fonts-loaded class to <html> if cookie is present.
 *
 * @since 1.0.1
 */
if (document.cookie.indexOf('emFontsLoaded=true') >= 0) {
	document.documentElement.classList.add('fonts-loaded');
}

/**
 * Add browser-specific classes to the body element.
 *
 * @since 1.0.1
 */
document.addEventListener('DOMContentLoaded', function () {
	var userAgent = navigator.userAgent.toLowerCase();
	var classes = [];

	/**
  * Add OS classes.
  */
	if (userAgent.match(/iphone|ipad/)) {
		classes.push('ios');
	} else if (userAgent.match(/android/)) {
		classes.push('android');
	} else if (userAgent.match(/macintosh/)) {
		classes.push('osx');
	} else if (userAgent.match(/windows/)) {
		classes.push('windows');
	} else if (userAgent.match(/linux/)) {
		classes.push('linux');
	}

	/**
  * Add Browser classes.
  */
	if (!!window.MSInputMethodContext && !!document.documentMode) {
		// https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
		classes.push('ie');
	} else if (userAgent.match(/edge/)) {
		classes.push('edge');
	} else if (userAgent.match(/chrome/)) {
		classes.push('chrome');
	} else if (userAgent.match(/firefox/)) {
		classes.push('gecko', 'firefox');
	} else if (userAgent.match(/safari/)) {
		classes.push('safari');
	}

	document.body.className += ' ' + classes.join(' ');
});

},{}]},{},[1])

